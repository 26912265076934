.main_container{
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}
.info_container{
    flex: 1 1;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}